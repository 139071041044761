import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SharedModule } from '../shared/Shared.Module';
import { Platform } from '@angular/cdk/platform';
declare var $: any;

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent implements OnInit {
  SEOKey="Contact Us, OneDropCab contact, Customer support, Inquiry, Feedback, Assistance, Reach us, Contact information, drop taxi number,one way cab contact number,drop taxi contact number,one way drop taxi contact number";
  SEODescription= "Get in touch with OneDropCab. Contact us for inquiries, feedback, or assistance. Our team is here to help you with reliable and prompt support.";
  SEOTitle="Contact Us - OneDropCab Support Team"
  constructor(private metaService: Meta, private titleService: Title, @Inject(DOCUMENT) private doc, @Inject(PLATFORM_ID) private platformId: string, ){
    
    this.createLinkAndMetas(this.SEOTitle, this.SEOKey, this.SEODescription, true, false);
    
  }
  ngOnInit(): void {
   
  }

  
  createLinkAndMetas(title: string, keywords: string, description: string, isCanonicalRequired: boolean, isLocation: boolean) {

    this.titleService.setTitle(title);
    var keys = "";
    var prekeys=keywords.split(",");
    if(isLocation)
    {
      var loc = this.doc.URL.split("/")[4];
      for(var i=0;i< prekeys.length; i++)
        {      
          keys+=prekeys[i]+" in "+loc+", "
        }
        keys = keys.substring(0,keys.length-1);
    }
    else{
      keys  = keywords
    }
   
    keys = keys.toLocaleLowerCase();
    this.metaService.updateTag(              
    {
      name: 'description', content: description
    })
    this.metaService.updateTag(              
    {
      name: 'keywords', content: keys
    })
    
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'og:image:alt', content:title });
    this.metaService.updateTag({ property: 'twitter:title', content: title });
    this.metaService.updateTag({ property: 'twitter:description', content: description });
    this.metaService.updateTag({ property: 'twitter:image', content: window.location.origin+'/assets/img/logo/logo1.png' });
    this.metaService.updateTag({ property: 'twitter:url', content:window.location.origin});
    this.metaService.updateTag({ property: 'twitter:site', content:'One Drop Cab'});
    if(isCanonicalRequired &&isPlatformBrowser(this.platformId))
    {
      const link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
    }
    this.hideLoader();
 }
 hideLoader(){

  setTimeout(() => {
      $('.preloader').fadeOut();
  }, 50);
}
}
